<template>
  <div class="category-plays">
    <el-page-header @back="goBack" :content="`${lotteryInfo.name} - 玩法配置`" />

    <el-card class="play-config">
      <!-- 顶部操作栏 -->
      <div class="toolbar">
        <el-button type="primary" size="medium" @click="addNavPlay">
          <i class="el-icon-plus"></i> 加玩法导航
        </el-button>
        <el-button type="warning" size="medium" @click="showCopyPlaysDialog">
          <i class="el-icon-document-copy"></i> 复制玩法
        </el-button>
        <el-button type="success" size="medium" @click="saveChanges" :loading="submitting">
          <i class="el-icon-check"></i> 保存修改
        </el-button>
      </div>

      <!-- 玩法树形表格 -->
      <el-tree ref="playTree" :data="playConfigs" :props="defaultProps" node-key="id"
        :default-expanded-keys="expandedKeys" draggable :allow-drag="allowDrag" :allow-drop="allowDrop"
        @node-drag-end="handleDragEnd" class="play-tree" v-loading="loading">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <!-- 玩法导航节点 -->
          <template v-if="data.type === 'nav'">
            <div class="nav-play-node">
              <div class="node-content">
                <template v-if="isMobile">
                  <div class="move-buttons">
                    <el-button type="text" icon="el-icon-top" @click.stop="moveNode(node, 'up')"></el-button>
                    <el-button type="text" icon="el-icon-bottom" @click.stop="moveNode(node, 'down')"></el-button>
                  </div>
                </template>
                <span class="play-name">{{ data.name }}</span>
              </div>
              <div class="node-actions">
                <div class="switch-wrapper" @click.stop>
                  <el-switch 
                    v-model="data.status" 
                    :active-value="1" 
                    :inactive-value="0"
                    @change="handleStatusChange(data)"
                  ></el-switch>
                </div>
                <div class="action-group">
                  <el-button type="primary" size="mini" @click.stop="renamePlay(data)">改名</el-button>
                  <el-button type="danger" size="mini" @click.stop="deletePlay(node, data)">删除</el-button>
                  <el-button type="success" size="mini" @click.stop="addPlay(data)">添加玩法</el-button>
                </div>
              </div>
            </div>
          </template>

          <!-- 玩法节点 -->
          <template v-else>
            <div class="play-node">
              <div class="node-content">
                <template v-if="isMobile">
                  <div class="move-buttons">
                    <el-button type="text" icon="el-icon-top" @click.stop="moveNode(node, 'up')"></el-button>
                    <el-button type="text" icon="el-icon-bottom" @click.stop="moveNode(node, 'down')"></el-button>
                  </div>
                </template>
                <span class="play-name">{{ data.name }}</span>
              </div>
              <div class="node-actions">
                <div class="switch-wrapper" @click.stop>
                  <el-switch 
                    v-model="data.status" 
                    :active-value="1" 
                    :inactive-value="0"
                    @change="handleStatusChange(data)"
                  ></el-switch>
                </div>
                <div class="action-group">
                  <el-button type="primary" size="mini" @click.stop="editPlayConfig(data)">编辑</el-button>
                  <el-button type="danger" size="mini" @click.stop="deletePlay(node, data)">删除</el-button>
                </div>
              </div>
            </div>
          </template>
        </span>
      </el-tree>
    </el-card>

    <!-- 改名对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="300px"
      :close-on-click-modal="false"
      @close="resetForm"
    >
      <el-form ref="playForm" :model="playForm" :rules="formRules">
        <el-form-item prop="name">
          <el-input 
            v-model="playForm.name" 
            placeholder="请输入名称"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="submitting">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改玩法编辑对话框 -->
    <el-dialog
      title="玩法配置"
      :visible.sync="playConfigDialogVisible"
      :width="isMobile ? '95%' : '700px'"
      :close-on-click-modal="false"
      :fullscreen="isMobile"
      @close="resetPlayConfigForm"
    >
      <el-form ref="playConfigForm" :model="playConfigForm" :rules="playConfigRules" 
        :label-width="isMobile ? '80px' : '100px'" 
        size="small"
      >
        <!-- 玩法名称 -->
        <el-form-item label="玩法名称" prop="name">
          <div class="name-copy-wrapper">
            <el-input v-model="playConfigForm.name" placeholder="请输入玩法名称"></el-input>
            <template v-if="!playConfigForm.isEdit">
              <el-select 
                v-model="playConfigForm.copyFromPlayId"
                placeholder="选择要复制的玩法"
                clearable
                style="width: 160px; margin: 0 10px;"
              >
                <el-option-group
                  v-for="nav in playConfigs"
                  :key="nav.id"
                  :label="nav.name"
                >
                  <el-option
                    v-for="play in nav.plays"
                    :key="play.id"
                    :label="play.name"
                    :value="play.id"
                  />
                </el-option-group>
              </el-select>
              <el-button 
                type="primary" 
                size="small"
                :disabled="!playConfigForm.copyFromPlayId"
                @click="handleCopyPlayConfig"
              >复制配置</el-button>
            </template>
          </div>
        </el-form-item>

        <!-- 玩法配置 -->
        <el-form-item label="玩法配置" prop="playConfig">
          <el-input 
            type="textarea" 
            v-model="playConfigForm.playConfig"
            :rows="isMobile ? 6 : 8"
            placeholder="请输入玩法配置JSON"
          ></el-input>
          <div class="form-tip">包含投注限额、号码范围等配置</div>
        </el-form-item>

        <!-- 中奖条件 -->
        <el-form-item label="中奖条件" prop="winningRules">
          <el-input 
            type="textarea" 
            v-model="playConfigForm.winningRules"
            :rows="isMobile ? 5 : 6"
            placeholder="请输入中奖条件JSON"
          ></el-input>
          <div class="form-tip">包含中奖判定规则</div>
        </el-form-item>

        <!-- 计算规则 -->
        <el-form-item label="计算规则" prop="calculationRules">
          <el-input 
            type="textarea" 
            v-model="playConfigForm.calculationRules"
            :rows="isMobile ? 5 : 6"
            placeholder="请输入计算规则JSON"
          ></el-input>
          <div class="form-tip">包含奖金计算规则</div>
        </el-form-item>

      </el-form>

      <div slot="footer" :class="{'mobile-dialog-footer': isMobile}">
        <el-button @click="playConfigDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPlayConfig" :loading="submitting">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 复制玩法对话框 -->
    <el-dialog
      title="复制玩法配置"
      :visible.sync="copyPlaysDialogVisible"
      width="500px"
      :close-on-click-modal="false"
    >
      <div v-loading="loadingLotteries">
        <el-form :model="copyPlaysForm" label-width="100px">
          <el-form-item label="选择彩种">
            <el-select 
              v-model="copyPlaysForm.sourceLotteryId" 
              placeholder="请选择要复制玩法的彩种"
              filterable
            >
              <el-option
                v-for="lottery in availableLotteries"
                :key="lottery.id"
                :label="lottery.name"
                :value="lottery.id"
              />
            </el-select>
            <div class="form-tip">只显示同类型且已配置玩法的彩种</div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="copyPlaysDialogVisible = false">取 消</el-button>
        <el-button 
          type="primary" 
          @click="handleCopyPlays" 
          :loading="copyingPlays"
          :disabled="!copyPlaysForm.sourceLotteryId"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LotteryPlaysConfig',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    category: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      lotteryInfo: {
        id: '',
        name: '',
        category: ''
      },
      loading: false,
      submitting: false,
      playConfigs: [],
      defaultProps: {
        children: 'plays',
        label: 'name'
      },
      isExpandAll: true,
      expandedKeys: [],
      isMobile: false,
      originalData: null, // 用于存储原始数据
      hasChanges: false,  // 是否有未保存的修改
      dialogVisible: false,
      dialogTitle: '',
      playForm: {
        name: '',
        id: null,
        type: '',
        isNew: false
      },
      formRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      },
      playConfigDialogVisible: false,
      playConfigForm: {
        id: '',
        name: '',
        isEdit: false,
        playConfig: '',
        winningRules: '',
        calculationRules: '',
        status: 1,
        sort: 0,
        copyFromPlayId: '', // 新增字段，用于存储要复制的玩法ID
      },
      playConfigRules: {
        name: [
          { required: true, message: '请输入玩法名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        playConfig: [
          { required: true, message: '请输入玩法配置', trigger: 'blur' }
        ],
        winningRules: [
          { required: true, message: '请输入中奖规则', trigger: 'blur' }
        ],
        calculationRules: [
          { required: true, message: '请输入计算规则', trigger: 'blur' }
        ]
      },
      copyPlaysDialogVisible: false,
      copyPlaysForm: {
        sourceLotteryId: ''
      },
      loadingLotteries: false,
      availableLotteries: [],
      copyingPlays: false
    }
  },
  created() {
    // 使用 props 而不是 route.params
    if (!this.id || !this.name || !this.category) {
      this.$message.warning('请从彩票列表进入!');
      this.$router.replace('/lottery/list');
      return;
    }

    this.lotteryInfo = {
      id: this.id,
      name: this.name,
      category: this.category
    };
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
    this.fetchPlayConfigs();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    goBack() {
      this.$router.push('/lottery/list');
    },
    handleStatusChange(data) {
      this.handleDataChange()
    },
    handleDataChange() {
      this.hasChanges = true;
      // 更新排序
      this.playConfigs.forEach((nav, index) => {
        nav.sort = index;
        if (nav.plays) {
          nav.plays.forEach((play, playIndex) => {
            play.sort = playIndex;
          });
        }
      });
    },
    async saveChanges() {
      try {
        this.submitting = true;
        // 移除 type 字段并确保所有必要字段都存在
        const dataToSave = this.playConfigs.map(nav => {
          const { type, ...navData } = nav;
          return {
            ...navData,
            lottery_id: this.lotteryInfo.id, // 确保包含 lottery_id
            plays: nav.plays?.map(play => {
              const { type: playType, ...playData } = play;
              return {
                ...playData,
                lottery_id: this.lotteryInfo.id, // 确保包含 lottery_id
                nav_id: nav.id, // 确保包含 nav_id
                // 确保这些字段是字符串格式
                playConfig: typeof playData.playConfig === 'string' 
                  ? playData.playConfig 
                  : JSON.stringify(playData.playConfig),
                winningRules: typeof playData.winningRules === 'string'
                  ? playData.winningRules
                  : JSON.stringify(playData.winningRules),
                calculationRules: typeof playData.calculationRules === 'string'
                  ? playData.calculationRules
                  : JSON.stringify(playData.calculationRules)
              };
            }) || []
          };
        });

        const res = await this.$http.put(
          `/api/admin/lottery/${this.lotteryInfo.id}/plays`, 
          dataToSave,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (res.data.code === 200) {
          this.$message.success('保存成功!');
          this.originalData = JSON.parse(JSON.stringify(this.playConfigs));
          this.hasChanges = false;
          await this.fetchPlayConfigs();
        }
      } catch (error) {
        console.error('保存失败:', error);
        this.$message.error('保存失败: ' + (error.response?.data?.message || error.message || '未知错误'));
      } finally {
        this.submitting = false;
      }
    },
    allowDrag(node) {
      return !this.isMobile
    },
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.data.type === 'nav') {
        return dropNode.data.type === 'nav' && type !== 'inner'
      }
      if (!draggingNode.data.type || draggingNode.data.type !== 'nav') {
        const dragParent = draggingNode.parent
        const dropParent = dropNode.parent
        if (dropNode.data.type === 'nav' && type === 'inner') {
          return true
        }
        return dragParent === dropParent && type !== 'inner'
      }
      return false
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      if (!dropNode) return
      this.handleDataChange()
    },
    moveNode(node, direction) {
      let targetArray
      let currentNode = node.data

      if (currentNode.type === 'nav') {
        targetArray = this.playConfigs
      } else {
        const navPlay = this.findNavPlay(currentNode.id)
        if (!navPlay || !navPlay.plays) return
        targetArray = navPlay.plays
      }

      const index = targetArray.findIndex(item => item.id === currentNode.id)
      if (index === -1) return

      if (direction === 'up' && index > 0) {
        const newArray = [...targetArray]
        const temp = newArray[index]
        newArray.splice(index, 1)
        newArray.splice(index - 1, 0, temp)
        
        if (currentNode.type === 'nav') {
          this.$set(this, 'playConfigs', newArray)
        } else {
          const navPlay = this.findNavPlay(currentNode.id)
          this.$set(navPlay, 'plays', newArray)
        }
        
        this.handleDataChange()
      } else if (direction === 'down' && index < targetArray.length - 1) {
        const newArray = [...targetArray]
        const temp = newArray[index]
        newArray.splice(index, 1)
        newArray.splice(index + 1, 0, temp)
        
        if (currentNode.type === 'nav') {
          this.$set(this, 'playConfigs', newArray)
        } else {
          const navPlay = this.findNavPlay(currentNode.id)
          this.$set(navPlay, 'plays', newArray)
        }
        
        this.handleDataChange()
      }
    },
    findNavPlay(playId) {
      for (const navPlay of this.playConfigs) {
        const play = navPlay.plays?.find(p => p.id === playId)
        if (play) return navPlay
      }
      return null
    },
    async deletePlay(node, data) {
      try {
        await this.$confirm('确认删除该玩法吗？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: this.isMobile ? 'mobile-confirm-dialog' : '',
          center: this.isMobile
        });

        // 如果是导航节点，检查是否有子玩法
        if (data.type === 'nav' && data.plays?.length > 0) {
          await this.$confirm(
            '该玩法导航下还有子玩法，确认一并删除吗？', 
            '警告', 
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              customClass: this.isMobile ? 'mobile-confirm-dialog' : '',
              center: this.isMobile
            }
          );
        }

        if (data.type === 'nav') {
          const index = this.playConfigs.findIndex(p => p.id === data.id);
          if (index > -1) {
            this.playConfigs.splice(index, 1);
          }
        } else {
          const navPlay = this.findNavPlay(data.id);
          if (navPlay) {
            const index = navPlay.plays.findIndex(p => p.id === data.id);
            if (index > -1) {
              navPlay.plays.splice(index, 1);
            }
          }
        }

        this.handleDataChange();
        this.$message.success('删除成功，请记得保存修改');
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error);
          this.$message.error('删除失败: ' + error.message);
        }
      }
    },
    async fetchPlayConfigs() {
      this.loading = true;
      try {
        const res = await this.$http.get(`/api/admin/lottery/${this.lotteryInfo.id}/plays`);
        if (res.data.code === 200) {
          this.playConfigs = res.data.data.map(nav => ({
            ...nav,
            type: 'nav',  // 添加 type 字段标识导航
            plays: nav.plays?.map(play => ({
              ...play,
              type: 'play'  // 添加 type 字段标识玩法
            })) || []
          }));
          this.originalData = JSON.parse(JSON.stringify(this.playConfigs));
          this.expandedKeys = this.playConfigs.map(nav => nav.id);
        }
      } catch (error) {
        console.error('获取玩法配置败:', error);
        this.$message.error('获取玩法配置失败');
      } finally {
        this.loading = false;
      }
    },
    // 添加玩法导航
    addNavPlay() {
      this.dialogTitle = '添加玩法导航';
      this.playForm = {
        name: '',
        id: `nav_${Date.now()}`,
        type: 'nav',
        isNew: true  // 添加标识，用于区分是新增还是修改
      };
      this.dialogVisible = true;
    },
    // 添加玩法
    addPlay(navData) {
      this.playConfigDialogVisible = true;
      this.playConfigForm = {
        id: `play_${navData.id.split('_')[1]}_${Date.now()}`,
        name: '',
        isEdit: false,
        playConfig: JSON.stringify({
          type: "rect",
          layout: {
            gap: 16,
            columns: 3,
            padding: 20
          },
          betting: {
            odds: 12,
            maxBet: 99999,
            minBet: -99999,
            selectLimit: {
              max: 12,
              min: 1,
              enabled: false
            },
            allowMultipleAmount: true
          },
          buttons: [
            {
              id: "rat",
              value: "rat",
              width: 160,
              height: 140,
              subText: "05 17 29 41",
              mainText: "鼠",
              colorClass: "green"
            },
            {
              id: "ox",
              value: "ox",
              width: 160,
              height: 140,
              subText: "04 16 28 40",
              mainText: "牛",
              colorClass: "red"
            },
            {
              id: "tiger",
              value: "tiger",
              width: 160,
              height: 140,
              subText: "03 15 27 39",
              mainText: "虎",
              colorClass: "red"
            },
            {
              id: "rabbit",
              value: "rabbit",
              width: 160,
              height: 140,
              subText: "02 14 26 38",
              mainText: "兔",
              colorClass: "red"
            },
            {
              id: "dragon",
              odds: 12,
              value: "dragon",
              width: 160,
              height: 140,
              subText: "01 13 25 37 49",
              mainText: "龙",
              colorClass: "red"
            },
            {
              id: "snake",
              value: "snake",
              width: 160,
              height: 140,
              subText: "12 24 36 48",
              mainText: "蛇",
              colorClass: "blue"
            },
            {
              id: "horse",
              value: "horse",
              width: 160,
              height: 140,
              subText: "11 23 35 47",
              mainText: "马",
              colorClass: "blue"
            },
            {
              id: "goat",
              value: "goat",
              width: 160,
              height: 140,
              subText: "10 22 34 46",
              mainText: "羊",
              colorClass: "blue"
            },
            {
              id: "monkey",
              value: "monkey",
              width: 160,
              height: 140,
              subText: "09 21 33 45",
              mainText: "猴",
              colorClass: "blue"
            },
            {
              id: "rooster",
              value: "rooster",
              width: 160,
              height: 140,
              subText: "08 20 32 44",
              mainText: "鸡",
              colorClass: "blue"
            },
            {
              id: "dog",
              value: "dog",
              width: 160,
              height: 140,
              subText: "07 19 31 43",
              mainText: "狗",
              colorClass: "red"
            },
            {
              id: "pig",
              value: "pig",
              width: 160,
              height: 140,
              subText: "06 18 30 42",
              mainText: "猪",
              colorClass: "green"
            }
          ],
          description: "选择生肖进行投注，每个生肖对应特定的号码，猜中开奖号码所属生肖即中奖。"
        }, null, 2),
        winningRules: JSON.stringify({
          type: "number_match",
          rules: {
            position: {
              end: 5,
              start: 0,
              description: "正码"
            },
            winCondition: {
              matchType: "include",
              description: "正码号码匹配",
              independentMatch: true
            }
          },
          description: "选中号码在开奖的前6个号码中即中奖，可多注中奖"
        }, null, 2),
        calculationRules: JSON.stringify({
          formula: "amount * odds",
          description: "中奖金额 = 投注金额 × 赔率"
        }, null, 2),
        status: 1,
        sort: navData.plays ? navData.plays.length : 0
      };
    },
    // 编辑玩法
    editPlayConfig(data) {
      this.playConfigDialogVisible = true;
      this.playConfigForm = {
        id: data.id,
        name: data.name,
        isEdit: true,
        playConfig: typeof data.playConfig === 'string' ? 
          data.playConfig : 
          JSON.stringify(data.playConfig, null, 2),
        winningRules: typeof data.winningRules === 'string' ? 
          data.winningRules : 
          JSON.stringify(data.winningRules, null, 2),
        calculationRules: typeof data.calculationRules === 'string' ? 
          data.calculationRules : 
          JSON.stringify(data.calculationRules, null, 2),
        status: data.status || 1,
        sort: data.sort || 0
      };
    },
    // 改名功能
    renamePlay(data) {
      this.dialogTitle = '修改名称';
      this.playForm = {
        name: data.name,
        id: data.id,
        type: 'nav',
        isNew: false  // 添加标识，用于区分是新增还是修改
      };
      this.dialogVisible = true;
    },
    // 提交改名表单
    async submitForm() {
      try {
        await this.$refs.playForm.validate();
        
        if (this.playForm.isNew) {
          // 添加新的导航
          const newNav = {
            id: this.playForm.id,
            name: this.playForm.name,
            type: 'nav',
            status: 1,
            sort: this.playConfigs.length,
            plays: []
          };
          
          this.playConfigs.push(newNav);
          this.$message.success('添加成功');
        } else {
          // 修改现有导航名称
          const nav = this.playConfigs.find(n => n.id === this.playForm.id);
          if (nav) {
            nav.name = this.playForm.name;
            this.$message.success('修改成功');
          }
        }
        
        this.handleDataChange();
        this.dialogVisible = false;
      } catch (error) {
        console.error('表单验证失败:', error);
      }
    },
    // 重置改名表单
    resetForm() {
      this.$refs.playForm?.resetFields();
      this.playForm = {
        name: '',
        id: null,
        type: '',
        isNew: false
      };
    },
    // 重置玩法编辑表单
    resetPlayConfigForm() {
      this.$refs.playConfigForm?.resetFields();
      this.playConfigForm = {
        id: '',
        name: '',
        isEdit: false,
        playConfig: JSON.stringify({
          betLimit: {
            minBet: 2,
            maxBet: 20000,
            unitBet: 2,
            maxBetNumber: 1000,
            betMode: ["yuan", "jiao", "fen"],
            mutiplier: {
              min: 1,
              max: 10000
            }
          },
          numberRule: {
            positions: [],
            numberRange: [1, 49],
            selectCount: [1, 1],
            orderMatters: false,
            repeatAllowed: false
          }
        }, null, 2),
        winningRules: JSON.stringify({
          type: "exact",
          matchCount: 1,
          positions: [],
          orderMatters: false,
          prizeLevel: [
            {
              level: 1,
              name: "一等奖",
              matchPattern: "all",
              probability: "1/49"
            }
          ]
        }, null, 2),
        calculationRules: JSON.stringify({
          baseOdds: 48.5,
          prizeLevels: [
            {
              level: 1,
              odds: 48.5,
              formula: "prize = bet * odds"
            }
          ],
          examples: [
            {
              bet: 2,
              odds: 48.5,
              prize: 97
            }
          ]
        }, null, 2),
        status: 1,
        sort: 0,
        copyFromPlayId: '', // 重置复制选项
      };
    },
    // 提交玩法编辑表单
    async submitPlayConfig() {
      try {
        await this.$refs.playConfigForm.validate();
        
        if (this.playConfigForm.isEdit) {
          // 编辑现有玩法
          const navPlay = this.findNavPlay(this.playConfigForm.id);
          if (navPlay) {
            const play = navPlay.plays.find(p => p.id === this.playConfigForm.id);
            if (play) {
              play.name = this.playConfigForm.name;
              // 直接存储文本
              play.playConfig = this.playConfigForm.playConfig;
              play.winningRules = this.playConfigForm.winningRules;
              play.calculationRules = this.playConfigForm.calculationRules;
              play.status = this.playConfigForm.status;
              this.$message.success('修改成功');
            }
          }
        } else {
          // 添加新玩法
          const navId = this.playConfigForm.id.split('_')[1];
          const navPlay = this.playConfigs.find(nav => nav.id === `nav_${navId}`);
          
          if (navPlay) {
            if (!navPlay.plays) {
              navPlay.plays = [];
            }
            
            navPlay.plays.push({
              id: this.playConfigForm.id,
              name: this.playConfigForm.name,
              type: 'play',
              // 直接存储文本
              playConfig: this.playConfigForm.playConfig,
              winningRules: this.playConfigForm.winningRules,
              calculationRules: this.playConfigForm.calculationRules,
              status: this.playConfigForm.status,
              sort: this.playConfigForm.sort
            });
            
            this.$message.success('添加成功!');
          }
        }
        
        this.handleDataChange();
        this.playConfigDialogVisible = false;
      } catch (error) {
        console.error('表单验证失败:', error);
        this.$message.error('保存失败：' + (error.message || '未知错误'));
      }
    },
    // 显示复制玩法对话框
    async showCopyPlaysDialog() {
      this.copyPlaysDialogVisible = true;
      await this.fetchAvailableLotteries();
    },

    // 获取可用的源彩种列表
    async fetchAvailableLotteries() {
      this.loadingLotteries = true;
      try {
        const res = await this.$http.get('/api/admin/lottery/list', {
          params: {
            page: 1,
            pageSize: 100
          }
        });
        
        if (res.data.code === 200) {
          // 只筛选同分类且非当前彩种的选项
          this.availableLotteries = res.data.data.list.filter(lottery => 
            lottery.id !== parseInt(this.lotteryInfo.id) && // 排除当前彩种
            lottery.category === parseInt(this.lotteryInfo.category)  // 同分类彩种
          );

          if (this.availableLotteries.length === 0) {
            this.$message.warning('没有找到其他同类型的彩种');
          }
        }
      } catch (error) {
        console.error('获取彩种列表失败:', error);
        this.$message.error('获取彩种列表失败');
      } finally {
        this.loadingLotteries = false;
      }
    },

    // 执行复制玩法操作
    async handleCopyPlays() {
      if (!this.copyPlaysForm.sourceLotteryId) {
        this.$message.warning('请选择要复制的源彩种');
        return;
      }

      try {
        this.copyingPlays = true;
        
        // 获取源彩种的玩法配置
        const res = await this.$http.get(`/api/admin/lottery/${this.copyPlaysForm.sourceLotteryId}/plays`);
        
        if (res.data.code === 200) {
          // 检查源彩种是否有玩法配置
          if (!res.data.data || !res.data.data.length) {
            this.$message.warning('所选彩种暂未配置玩法');
            return;
          }

          // 如果当前已有玩法配置，需要确认是否覆盖
          if (this.playConfigs.length > 0) {
            await this.$confirm(
              '复制将覆盖当前彩种的所有玩法配置，是否继续？', 
              '警告', 
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                customClass: 'mobile-confirm-dialog',
                center: this.isMobile
              }
            );
          }

          // 处理源彩种数据，生成新的ID并添加必要的类型标记
          const timestamp = Date.now();
          const copiedPlays = res.data.data.map((nav, navIndex) => {
            // 为导航生成新ID
            const newNavId = `nav_${this.lotteryInfo.id}_${timestamp}_${navIndex}`;
            return {
              ...nav,
              id: newNavId, // 替换导航ID
              type: 'nav',
              plays: nav.plays?.map((play, playIndex) => ({
                ...play,
                id: `play_${this.lotteryInfo.id}_${timestamp}_${navIndex}_${playIndex}`, // 替换玩法ID
                type: 'play'
              })) || []
            };
          });

          // 更新当前彩种的玩法配置
          this.playConfigs = copiedPlays;
          this.handleDataChange();
          this.$message.success('玩法配置复制成功');
          this.copyPlaysDialogVisible = false;
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('复制玩法失败:', error);
          this.$message.error('复制玩法失败');
        }
      } finally {
        this.copyingPlays = false;
      }
    },
    // 处理复制玩法配置
    handleCopyPlayConfig() {
      const playId = this.playConfigForm.copyFromPlayId;
      if (!playId) {
        this.$message.warning('请先选择要复制的玩法');
        return;
      }

      // 查找选中的玩法
      let selectedPlay = null;
      for (const nav of this.playConfigs) {
        const play = nav.plays?.find(p => p.id === playId);
        if (play) {
          selectedPlay = play;
          break;
        }
      }

      if (selectedPlay) {
        // 复制配置到当前表单
        this.playConfigForm.playConfig = typeof selectedPlay.playConfig === 'string' ? 
          selectedPlay.playConfig : 
          JSON.stringify(selectedPlay.playConfig, null, 2);
        
        this.playConfigForm.winningRules = typeof selectedPlay.winningRules === 'string' ? 
          selectedPlay.winningRules : 
          JSON.stringify(selectedPlay.winningRules, null, 2);
        
        this.playConfigForm.calculationRules = typeof selectedPlay.calculationRules === 'string' ? 
          selectedPlay.calculationRules : 
          JSON.stringify(selectedPlay.calculationRules, null, 2);

        this.$message.success('配置复制成功');
        this.playConfigForm.copyFromPlayId = ''; // 复制完成后清空选择
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.category-plays {
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .el-page-header {
    margin-bottom: 20px;
  }

  .play-config {
    .toolbar {
      display: flex;
      gap: 12px;
      margin-bottom: 20px;
    }

    .play-tree {
      margin-top: 20px;
      font-size: 14px;
      padding: 0 8px;

      :deep(.el-tree-node__content) {
        height: auto;
        padding: 0;
      }

      :deep(.el-tree-node__children) {
        padding-left: 24px;
      }

      // 移除分隔线和间距
      :deep(.el-tree-node) {
        &:not(:last-child) {
          padding-bottom: 0;
          margin-bottom: 0;
          
          &::after {
            display: none;
          }
        }
      }

      .custom-tree-node {
        flex: 1;
        min-width: 0;

        // 通用节点样式
    .nav-play-node,
    .play-node {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      padding: 4px 12px;
          border-bottom: 1px solid #EBEEF5;

      .node-content {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        min-width: 0;

            .move-buttons {
              display: flex;
              gap: 8px;
              margin-right: 12px;

              .el-button {
                padding: 4px 8px;
                font-size: 18px;
                
                &:hover {
                  color: #409EFF;
                }
              }
            }
      }

      .node-actions {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-shrink: 0;

        .action-group {
          display: flex;
          gap: 12px;
        }
      }
    }

        // 导航节点特殊样式
        .nav-play-node {
          min-height: 42px;

          .play-name {
            font-size: 16px;
            font-weight: 600;
            color: #303133;
          }
        }

        // 玩法节点特殊样式
        .play-node {
          min-height: 32px;

          .play-name {
            font-size: 13px;
            font-weight: 600;
            color: #606266;
          }
        }
      }
    }
  }

  // 添加删除钮的式
  .danger {
    color: #F56C6C;
  }
}

// 移动端适配
@media screen and (max-width: 768px) {
  .play-tree {
    .custom-tree-node {
      .nav-play-node,
      .play-node {
        flex-direction: column;
        align-items: stretch;
        gap: 12px;
        padding: 12px;

        .node-content {
          margin-bottom: 8px;

          .move-buttons .el-button {
            padding: 8px 12px;
            
            i {
              font-size: 20px;
            }
          }
        }

        .node-actions {
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 12px;

          .action-group {
            width: 100%;
            
            .el-button {
              flex: 1;
              margin-left: 0;
              padding: 8px 15px;
              font-size: 14px;
              
              &.danger {
                color: #fff;
                background-color: #F56C6C;
                border-color: #F56C6C;
              }
              
              &:not(.danger) {
                color: #fff;
                background-color: #409EFF;
                border-color: #409EFF;
              }
            }
          }
        }
      }
    }
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
.play-tree {
  .custom-tree-node {
    .nav-play-node,
    .play-node {
        border-bottom-color: #304156;
      }

      .nav-play-node .play-name {
        color: #e6e6e6;
      }

      .play-node {
        .play-name {
          color: #c0c4cc;
        }
        
        .odds-label {
          color: #808080;
        }
      }
    }
  }

  .form-section {
    background: #1e1e1e;
    
    .section-title {
      color: #e6e6e6;
    }
  }
}

// 表单相关样式
.form-section {
  margin-bottom: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  .section-title {
    font-size: 15px;
    font-weight: 600;
    color: #303133;
    margin-bottom: 15px;
    padding-left: 8px;
    border-left: 3px solid #409EFF;
  }
}

.form-tip {
  font-size: 12px;
  color: #909399;
  line-height: 1.2;
  margin-top: 4px;
}

.switch-wrapper {
  display: inline-block;
  cursor: pointer;
}

.name-copy-wrapper {
  display: flex;
  align-items: center;
  
  .el-input {
    flex: 1;
  }
}
</style>